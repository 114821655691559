/**
 * 获取基准日期前n天的时间
 * @param baseDate-----Date
 * @param num
 * return date
 * */
export function getBeforeDay(baseDate,num){
    let oneDay = 24 * 60 * 60 * 1000;
    let retTime = new Date(baseDate.getTime() - num*oneDay);
    return retTime;
}

export function getBeforeDayYMD(baseDate,num){
    let oneDay = 24 * 60 * 60 * 1000;
    let dd = new Date(baseDate.getTime() - num*oneDay);

    var y = dd.getFullYear();
    var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
    var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
    return y + "-" + m + "-" + d;
    return dd;
}

/**
 * 获取基准日期后n天的时间
 * @param baseDate-----Date
 * @param num
 * return date
 * */
export function getAfterDay(baseDate,num){
    let oneDay = 24 * 60 * 60 * 1000;
    let retTime = new Date(baseDate.getTime() + 7*oneDay);
    return retTime;
}

/**
 * JS 计算两个时间间隔多久（时分秒）
 * @param startTime "2019-10-23 15:27:23"
 * @param endTime "2019-10-23 15:27:55"
 * @return 1天2时3分5秒
 */
export function twoTimeInterval(startTime, endTime) {

    // 开始时间
    let d1 = startTime.replace(/\-/g, "/");
    let date1 = new Date(d1);

    // 结束时间
    let d2 = endTime.replace(/\-/g, "/");
    let date2 = new Date(d2);

    // 时间相差秒数
    let dateDiff = date2.getTime() - date1.getTime();

    // 计算出相差天数
    let days = Math.floor(dateDiff / (24 * 3600 * 1000));

    // 计算出小时数
    let residue1 = dateDiff % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
    let hours = Math.floor(residue1 / (3600 * 1000));

    // 计算相差分钟数
    let residue2 = residue1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
    let minutes = Math.floor(residue2 / (60 * 1000));

    // 计算相差秒数
    let residue3 = residue2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
    let seconds = Math.round(residue3 / 1000);

    let returnVal =
        ((days == 0) ? "" : days + " 天 ") +
        ((hours == 0) ? "" : hours + " 小时 ") +
        ((minutes == 0) ? "" : minutes + " 分 ") +
        ((seconds == 0) ? "" : seconds + " 秒");

    return returnVal;
}

/**
 *
 * 计算两个时间之间天数跨度
 * @param startTime "2019-10-23 15:27:23"
 * @param endTime "2019-10-23 15:27:55"
 * */
export function twoTimeDayInterval(startTime, endTime) {
    // 开始时间
    let d1 = startTime.replace(/\-/g, "/");
    let date1 = new Date(d1);

    // 结束时间
    let d2 = endTime.replace(/\-/g, "/");
    let date2 = new Date(d2);

    // 时间相差秒数
    let dateDiff = date2.getTime() - date1.getTime();

    // 计算出相差天数
    let days = Math.ceil(dateDiff / (24 * 3600 * 1000));
    return days;
}

/**
 *
 * 计算两个时间之间小时数跨度
 * @param startTime "2019-10-23 15:27:23"
 * @param endTime "2019-10-23 15:27:55"
 * */
export function twoTimeHourInterval(startTime, endTime) {
// 开始时间
    let d1 = startTime.replace(/\-/g, "/");
    let date1 = new Date(d1);

    // 结束时间
    let d2 = endTime.replace(/\-/g, "/");
    let date2 = new Date(d2);

    // 时间相差秒数
    let dateDiff = date2.getTime() - date1.getTime();

    // 计算出小时数
    let hours = (dateDiff / (3600 * 1000)).toFixed(2);
    return hours;
}

/**
 *
 * 计算两个时间之间分钟数数跨度
 * @param startTime "2019-10-23 15:27:23"
 * @param endTime "2019-10-23 15:27:55"
 * */
export function twoTimeMinutesInterval(startTime, endTime) {
// 开始时间
    let d1 = startTime.replace(/\-/g, "/");
    let date1 = new Date(d1);

    // 结束时间
    let d2 = endTime.replace(/\-/g, "/");
    let date2 = new Date(d2);

    // 时间相差秒数
    let dateDiff = date2.getTime() - date1.getTime();

    // 计算相差分钟数
    let minutes = Math.round(dateDiff / (60 * 1000));
    return minutes;
}

/**
 * 日期格式化
 * time（Date）
 * */
export function dayFormat(time) {
    // 时间格式化 2019-09-08
    let year = time.getFullYear();
    let month = time.getMonth() + 1 >= 10 ? time.getMonth()+1 : '0'+(time.getMonth()+1);
    let day = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate();
    return year + "-" + month + "-" + day;
}

/**
 * 月份格式化
 * time（Date）
 * */
export function monthFormat(time) {
    // 时间格式化 2019-09-08
    let year = time.getFullYear();
    let month = time.getMonth() + 1 >= 10 ? time.getMonth()+1 : '0'+(time.getMonth()+1);
    return year + "-" + month;
}
