import { createAPI } from '@/utils/request'
const urlStr = '/service-baseinfo'
const imgUrl = '/springcloud-service-provider01'


export const getBatteryTypeAll = data=>createAPI(urlStr+'/btytype/list' + data.url,'get')
// POST
// /btytype/addBtyType
// 电池型号管理增加
export const addNewBtytype=data=>createAPI(urlStr+'/btytype/addBtyType','POST',data)
///btytype/page 分页查询电池型号信息
export const getBtytypePage= data=>createAPI(urlStr+"/btytype/specificPage?page="+data.page+"&size="+data.size + data.url,'get')
////btytype/batch
// 电池型号管理批量删除
export const delTyType=id=>createAPI(urlStr+"/btytype/batch?ids="+id,'delete')
// /btytype/excelImport/btytypes
// 电池型号管理批量导入

export const importExcelTyTypes=data=>createAPI(urlStr+"/btytype/excelImport/btytypes",'post',data)

// POST
// /btytype/update
// 电池型号管理修改

export const upBtytype=data=>createAPI(urlStr+'/btytype/update','POST',data)

///btytype/specificPage 根据名称状态分页查询电池型号信息
export const getTyTypeOfLevel =data=>createAPI(urlStr+"/btytype/queryByLevel?" + data.url,'get')

//分页查询电池基础信息
export const batteryPage = data => createAPI(urlStr + '/batteryBaseInfo/page?' + data.url, 'get')
//新增电池信息
export const addBattery=data=>createAPI(urlStr+'/batteryBaseInfo/add','POST',data)
//修改电池信息
export const updateBattery=data=>createAPI(urlStr+'/batteryBaseInfo/update','PUT',data)
//删除电池信息
export const deleteBattery=data=>createAPI(urlStr+'/batteryBaseInfo/delete/','POST',data)
//批量删除电池信息
export const batchDelBattery =data=>createAPI(urlStr+'/batteryBaseInfo/batch?ids='+data,'delete')
//获取电池所有信息
export const getBatteryAll = ()=>createAPI(urlStr+'/batteryBaseInfo/list','get')
//电池信息批量导入
export const batteryLeadFile = data =>createAPI(urlStr+'/batteryBaseInfo/excelImport','post',data)




