import { createAPI } from '@/utils/request'
//const urlStr = '/service-baseinfo'
const urlStr = '/service-business1'
// POST
// /condingrules/addCodingRule
// 编码规则管理增加
export const addNewCodingRule=data=>createAPI(urlStr+'/condingrules/addCodingRule','post',data)
// DELETE

// 编码规则管理批量删除
export const delCodingRule=id=>createAPI(urlStr+'/condingrules/batch?idList='+id,'delete')
// POST
// /condingrules/excelImport/condingrules
// 编码规则管理批量导入
export const importExcelCodingRules=data=>createAPI(urlStr+'/condingrules/excelImport/condingrules','post',data)
// GET
// /condingrules/findall
// 编码规则管理查询所有
export const getCodingAll = ()=>createAPI(urlStr+'/condingrules/findall','get')
// GET
// /condingrules/page
// 分页查询编码规则
export const getAllCodingRules=data=>createAPI(urlStr+'/condingrules/page?page='+data.page+'&size='+data.size,'get',data)
// POST
// /condingrules/update
// 编码规则管理修改
export const upCodingRule=data=>createAPI(urlStr+'/condingrules/update','post',data)
// /condingrules/specifcPage 根据名字和状态分页查询
export const getCodingOfStatus=data=>createAPI(urlStr+'/condingrules/specifcPage?name='+data.name+"&state="+data.state+"&page="+data.page+"&size="+data.size)
