import { createAPI } from '@/utils/request'
const urlStr = '/service-pay'
//获取账户信息
export const getTeamAccounts = data=>createAPI(urlStr+"/pay/account/selectAccountListByTeamId?teamId=" + data,'GET')
//更新账户信息
export const updateAccount = data=>createAPI(urlStr+"/pay/account/updateAccount",'POST',data)
//分页查询
export const getAccountPage = data=>createAPI(urlStr+"/pay/account/pageQuery"+data.url,'POST',data)

//新增账户信息
export const addAccount = data=>createAPI(urlStr+"/pay/account/createAccount",'POST',data)
//删除账户信息
export const delAccounts= data=>createAPI(urlStr+'/pay/account/batchDelete','POST',data)

//账户充值信息
export const createAccountCharge = data=>createAPI(urlStr+"/pay/account/accountCharge",'POST',data)

//账户充值记录查询
export const queryChargeRecord = data=>createAPI(urlStr+"/pay/account/queryRecord"+data.url,'POST',data)

