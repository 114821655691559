import { createAPI } from '@/utils/request'
const urlStr = '/service-baseinfo'
const url='/cloud-platform'
    //-------------------------电池追溯日志---------------------------------------------------------
    // /chargingTraceLog/page
    // 分页查询电池追溯日志
// export const getChargingTraceLogs = data => createAPI(urlStr + "/chargingTraceLog/page",'get',data)

export const ChargingTraceInfoPage = (current, size, data)=>createAPI(url+'/cloudPlatform/ChargingTraceInfoPage/' + current + "/" + size,'post',data)

//获取所有电池追溯日记
export const getChargingAll =()=>createAPI(urlStr+'/chargingTraceLog/list','get')

// /chargingTraceLog/add
// 新增电池追溯日志
export const upChargingTraceLog = data => createAPI(urlStr + '/chargingTraceLog/add', 'post', data)
    // GET
    // /chargingTraceLog/specificPage
    // 根据条件分页查询
export const getBatteryDistributingInfoOfbtyNo = data => createAPI(urlStr + '/chargingTraceLog/specificPage', 'get',data)
    // ----------------------电池实时信息表 电池投入情况展示------------------------------------------------------------

//获取所有电池实时信息
export const getBatterAll = ()=>createAPI(urlStr+'/batteryRealResult/list','get')

// 新增电池实时信息
// POST
// /batteryRealResult/add
export const addBatteryRealResult = data => createAPI(urlStr + '/batteryRealResult/add', 'post', data)
    // DELETE
    // /chargingTraceLog/batch
    // 批量删除
export const delsBatteryRealResults = arr => createAPI(urlStr + '/chargingTraceLog/batch?idList=' + arr, 'delete')
    // GET
    // /batteryRealResult/page
    // 分页查询电池实时信息
export const getBatteryRealResultOfPage = data => createAPI(urlStr + '/batteryRealResult/page', 'get',data)
    // PUT
    // /batteryRealResult/update
    // 更新电池实时信息
export const upBatteryRealResult = data => createAPI(urlStr + '/batteryRealResult/update', 'put', data)
    // /batteryRealResult/specificPage
    // 根据条件分页查询
export const getBatteryRealResultOfstate = data => createAPI(urlStr + '/batteryRealResult/specificPage', 'get',data)


//-------------------电池维护日志---------------------------------------------------

//获取所有电池维护日志
export const getMainAll =()=>createAPI(urlStr+'/batteryMainTainLog/list','get')

// POST
// /batteryMainTainLog/add
// 新增电池维护日志
export const addBatteryMainTainLog = data => createAPI(urlStr + '/batteryMainTainLog/add', 'post', data)

// GET
// /batteryMainTainLog/page
// 分页查询电池维护日志
export const getBatteryMainTainLogOfpage = data => createAPI(urlStr + '/batteryMainTainLog/page', 'get',data)
    // /batteryMainTainLog/batch
    // 批量删除
export const delChargingTraceLogs = arr => createAPI(urlStr + '/batteryMainTainLog/batch?idList=' + arr, 'delete')
    // GET
    // /batteryMainTainLog/specificPage
    // 根据条件分页查询
export const getBatteryMainTainLogOfbtyNo = data => createAPI(urlStr + '/batteryMainTainLog/specificPage', 'get',data)
    //------------------------电池分布数据-------------------------------------------------------

//获取所有电池分布数据
export const getBranchAll =()=>createAPI(urlStr+'')

// POST
// /batteryDistributingInfo/add
// 新增电池分布数据
export const addatteryDistributingInfo = data => createAPI(urlStr + '/batteryDistributingInfo/add', 'post', data)
    // DELETE
    // /batteryDistributingInfo/batch areaCodes
    // 批量删除
export const delsBatteryDistributingInfos = areaCodes => createAPI(urlStr + '/batteryDistributingInfo/batch?areaCodes=' + areaCodes, 'DELETE')
    // GET
    // /batteryDistributingInfo/page
    // 分页查询电池分布数据
export const getBatteryDistributingInfoOfpage = data => createAPI(urlStr + '/batteryDistributingInfo/queryByRegionCode', 'get',data)
    // PUT
    // /batteryDistributingInfo/update
    // 更新电池分布数据
export const upBatteryDistributingInfo = data => createAPI(urlStr + '/batteryDistributingInfo/update', 'put', data)
