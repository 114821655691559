import { createAPI } from '@/utils/request'

const urlStr = '/service-baseinfo'
const imgUrl = '/springcloud-service-provider01'

//获取所有区域信息
export const getArerCodeAll =()=>createAPI(urlStr+'/region/list','get')



//分页查询区域信息 /bdregion/page

export const getInformation = data=>createAPI(urlStr+'/region/page','get',data)
///bdregion/excelImport/bdregions
// 区域信息批量导入BtyTypeInfo
export const upExcelImportDregion=data=>createAPI(urlStr+'/region/excelImport','post',data)
///bdregion/addBtyType
// 区域信息增加
export const addDregion=data=>createAPI(urlStr+'/region/addBtyType','post',data)

// /bdregion/batch批量删除
export const delDregion=id=>createAPI(urlStr+"/region/batch?ids="+id,'delete')

//findBycodeOrname 根据areacode或areaname查询
export const findBycodeOrname=data=>createAPI(urlStr+"/region/findBycodeOrname?codeOrname="+data,'get')
// /bdregion/update区域信息修改
export const upDregion=data=>createAPI(urlStr+"/region/update",'post',data)

// /bdregion/specificPage根据条件分页查询
export const findSpecificPage=data=>createAPI(urlStr+"/region/specificPage",'get',data)
