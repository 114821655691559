//客户管理
import { createAPI } from '@/utils/request'
const urlStr = '/service-pay'
// const urlStr = '/service-miniapp'

//车辆认证分页查询
export const customerVehiclePage = data=> createAPI(urlStr + "/pay/customerVehicle/pageQuery" + data.url,'post',data);
// export const customerVehiclePage = data=> createAPI(urlStr + "/customerVehicle/specificPageWithoutPerm"+data.url,'get');

//车辆审核
export const customerVehicleAudit = data => createAPI(urlStr + '/pay/customerVehicle/audit', 'post',data)

//批量删除审核信息
export const batchDeleteCarAuth = data=> createAPI(urlStr + "/pay/customerVehicle/batchDelete?ids="+data,'get')

export const addVehicleAuth = data => createAPI(urlStr + '/pay/customerVehicle/add', 'post',data)

export const updateVehicleAuth = data => createAPI(urlStr + '/pay/customerVehicle/update', 'post',data)

//客户分页查询
export const customerPage = data => createAPI(urlStr + "/pay/customer/pageQuery",'post',data);

export const bindVehicleAccount = data => createAPI(urlStr + '/pay/customerVehicle/bindVehicleAccount', 'post',data)

