import Vue from 'vue'
import Vuex from 'vuex'
import * as myStore from "@/utils/stroage";
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        // user: getItem('electric-user'),
        // token: getItem('electric-token'),
        user: myStore.sessionGetItem('electric-user'),
        token: myStore.sessionGetItem('electric-token'),
        tags: myStore.sessionGetItem('tags') || [{
            id: '0',
            name: '首页',
            path: 'home'
        }],
        rolesStoreList: [],
        carDuration:1,
    },
    mutations: {
        // t-box页倍速
        getduration(state,e){
            state.carDuration = e
            console.log(state.carDuration,'vuex',e)
        },
        userToken(state, data) {
            myStore.sessionRemoveItem('electric-token')
            myStore.sessionSetItem('electric-token', data)
            // removeItem('electric-token')
            // setItem('electric-token', data)
            state.token = data
        },
        userInfo(state, data) {
            myStore.sessionRemoveItem('electric-user')
            myStore.sessionSetItem('electric-user', data)
            // removeItem('electric-user')
            // setItem('electric-user', data)
            state.user = data
        },
        setTags(state, data) {
            state.tags = data
            myStore.sessionSetItem("tags", data);
        },
        removeState(state, status) {
            if (status) {
                myStore.sessionRemoveItem("tags");
                state.user = ''
                state.token = ''
                state.tags = [{
                    id: '0',
                    name: '首页',
                    path: 'home'
                }]
            }
        },
        stateMsg(state, data) {
            state.user = null
            state.token = null
        },
        getRoles(state, data) {
            state.rolesStoreList = data
        }
    },
    actions: {},
    modules: {},


})
