import Vue from 'vue'
import store from '@/store/index'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    }, {
        path: '/login',
        name: 'login',
        component: () =>
            import ('@/views/login.vue')
    },
    {
        path: '/index',
        name: 'index',
        component: () =>
             import ('@/views/home/index.vue')
    },
    {
        path: '/',
        redirect: '/home',
        component: () =>
            import ('@/views/webcome.vue'),
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/home/index.vue')
            },
            {
                path: '/user',
                name: 'user',
                component: () => import('@/views/system/user/index.vue')
            },
            // {
            //   path: 'user/form',
            //   name: '用户添加',
            //   component: () => import('@/views/userAdmin/user/index.vue'),
            //   meta: { title: '用户添加' },
            //   hidden: true
            // },
            //角色管理
            {
                path: '/roles',
                name: 'roles',
                component: () => import('@/views/system/roles/index.vue')
            },
            {
                path: '/authority',
                name: 'authority',
                component: () => import('@/views/system/roles/authority.vue')
            },
            //菜单管理
            {
                path: '/auth',
                name: 'auth',
                component: () => import('@/views/system/menu/index.vue')
            },

            //运营商参数
            {
                path: '/sysConfig',
                name: 'sysConfig',
                component: () => import('@/views/system/sysConfig/index.vue')
            },


            {
                path: '/recompose',
                name: 'recompose',
                component: () => import('@/views/system/recompose/index.vue')
            },

            //小程序首页轮播图
            {
                path: '/appBanner',
                name: 'appBanner',
                component: () => import('@/views/system/appBanner/index.vue')
            },

            {
                path: '/dict-type',
                name: 'dictType',
                component: () => import('@/views/system/dict/index')
            },
            {
                path: '/dict-data/:dictId(\\d+)',
                name: 'dictData',
                component: () => import('@/views/system/dict/data')
            },


            //基础信息
            {
                path: '/area',
                name: 'area',
                component: () => import('@/views/retain/area/index.vue')
            },

            //电池型号
            {
                path: '/battery',
                name: 'battery',
                component: () => import('@/views/basics/batteryManager/batteryType.vue')
            },

            //电池基础信息
            {
                path: '/batteryBasic',
                name: 'batteryBasic',
                component: () => import('@/views/basics/batteryManager/index.vue')
            },
            //车队基础信息
            {
                path: '/vehicleTeam',
                name: 'vehicleTeam',
                component: () => import('@/views/customer/teamManager/index.vue')
            },
            //账户管理
            {
                path: '/account',
                name: 'account',
                component: () => import('@/views/customer/teamManager/account.vue')
            },
            //运营商基础信息
            {
                path: '/operator',
                name: 'operator',
                component: () => import('@/views/system/operator/index.vue')
            },

            {
                path: '/teamBinds',
                name: 'teamBinds',
                component: () => import('@/views/system/operator/teamBinds.vue')
            },
            {
                path: '/userOperator',
                name: 'userOperator',
                component: () => import('@/views/system/user/userOperator.vue')
            },
            {
                path: '/vehicleMonitor',
                name: 'vehicleMonitor',
                component: () => import('@/views/basics/vehicleManager/vehicleMonitor.vue')
            },
            {
                path: '/coding',
                name: 'coding',
                component: () => import('@/views/retain/coding/index.vue')
            },
            {
                path: '/facility',
                name: 'facility',
                component: () => import('@/views/retain/facility/index.vue')
            },
            {
                path: '/modelCar',
                name: 'modelCar',
                component: () => import('@/views/basics/vehicleManager/vehicleType.vue')
            },
            {
                path: '/cyclicPic',
                name: 'cyclicPic',
                component: () => import('@/views/retain/cyclicPic/index.vue')
            },
            // ==============================================================
            {
                path: '/stationMoneyFactor',
                name: 'stationMoneyFactor',
                component: () => import('@/views/retain/stationMoneyFactor/index.vue')
            },
            {
                path: '/node',
                name: 'node',
                component: () => import('@/views/basics/stationAreaManager/index.vue')
            },
            {
                path: '/stationType',
                name: 'stationType',
                component: () => import('@/views/basics/stationTypeManager/index.vue')
            },
            // ================================================================
            // 场站
            //场站列表
            {
                path: '/force',
                name: 'force',
                component: () => import('@/views/basics/stationManager/index.vue')
            },
            //场站详情
            {
                path: '/forceMsg',
                name: 'forceMsg',
                component: () => import('@/views/basics/stationManager/forceMsg.vue')
            },

            //场站设备
            {
                path: '/equipment',
                name: 'equipment',
                component: () => import('@/views/basics/stationManager/equipment.vue')
            },

            //充电机数据
            {
                path: '/chargeDev',
                name: 'chargeDev',
                component: () => import('@/views/basics/stationManager/chargeDev.vue')
            },
            //电池数据
            {
                path: '/batData',
                name: 'batData',
                component: () => import('@/views/basics/stationManager/batData.vue')
            },
            //换电预约审核
            {
                path: '/powerChangeReservation',
                name: 'powerChangeReservation',
                component: () => import('@/views/basics/stationManager/powerChangeReservation.vue')
            },
            //版本管理
            {
                path: '/versionManage',
                name: 'versionManage',
                component: () => import('@/views/basics/versionManage/index.vue')
            },

            //远程升级
            {
                path: '/remoteUpgrade',
                name: 'remoteUpgrade',
                component: () => import('@/views/basics/versionManage/upgrade.vue')
            },
            {
                path: '/tBoxUpgrade',
                name: 'tBoxUpgrade',
                component: () => import('@/views/basics/versionManage/tBoxUpgrade.vue')
            },
            {
                path: '/upgrade',
                name: 'upgrade',
                component: () => import('@/views/StandAccused/upgrade/index.vue')
            },

            {
                path: '/backward',
                name: 'backward',
                component: () => import('@/views/station/backward/index.vue')
            },
            {
                path: '/cellInfo',
                name: 'cellInfo',
                component: () => import('@/views/station/cellInfo/index.vue')
            },
            {
                path: '/codingRule',
                name: 'codingRule',
                component: () => import('@/views/station/codingRule/index.vue')
            },

            {
                path: '/cellspace',
                name: 'cellspace',
                component: () => import('@/views/station/cellspace/index.vue')
            },
            {
                path: '/maintain',
                name: 'maintain',
                component: () => import('@/views/station/maintain/index.vue')
            },

            // ========================================================================================================
            {
                path: '/equipmentModel',
                name: 'equipmentModel',
                component: () => import('@/views/station2/equipmentModel/index.vue')
            },
            {
                path: '/vehicleModels',
                name: 'vehicleModels',
                component: () => import('@/views/station2/vehicleModels/index.vue')
            },
            {
                path: '/monitoring',
                name: 'monitoring',
                component: () => import('@/views/station2/monitoring/index.vue')
            },
            {
                path: '/recharge',
                name: 'recharge',
                component: () => import('@/views/station2/recharge/index.vue')
            },
            {
                path: '/pcsEqm',
                name: 'pcsEqm',
                component: () => import('@/views/station2/pcsEqm/index.vue')
            },
            {
                path: '/powerDistribution',
                name: 'powerDistribution',
                component: () => import('@/views/station2/powerDistribution/index.vue')
            },
            {
                path: '/pwrUnit',
                name: 'pwrUnit',
                component: () => import('@/views/station2/pwrUnit/index.vue')
            },
            {
                path: '/hvacSystem',
                name: 'hvacSystem',
                component: () => import('@/views/station2/hvacSystem/index.vue')
            },
            {
                path: '/fireControl',
                name: 'fireControl',
                component: () => import('@/views/station2/fireControl/index.vue')
            },
            {
                path: '/largeScreen',
                name: 'largeScreen',
                component: () => import('@/views/station2/largeScreen/index.vue')
            },
            {
                path: '/binGate',
                name: 'binGate',
                component: () => import('@/views/station2/binGate/index.vue')
            },
            {
                path: '/gateMac',
                name: 'gateMac',
                component: () => import('@/views/station2/gateMac/index.vue')
            },
            {
                path: '/viEqm',
                name: 'viEqm',
                component: () => import('@/views/station2/viEqm/index.vue')
            },
            {
                path: '/auxEqm',
                name: 'auxEqm',
                component: () => import('@/views/station2/auxEqm/index.vue')
            },
            {
                path: '/energyEqm',
                name: 'energyEqm',
                component: () => import('@/views/station2/energyEqm/index.vue')
            },
            {
                path: '/alarmThreshold',
                name: 'alarmThreshold',
                component: () => import('@/views/station2/alarmThreshold/index.vue')
            },
            {
                path: '/eqmBase',
                name: 'eqmBase',
                component: () => import('@/views/station2/eqmBase/index.vue')
            },
            {
                path: '/acData',
                name: 'acData',
                component: () => import('@/views/station2/acData/index.vue')
            },
            {
                path: '/chargeDevData',
                name: 'chargeDevData',
                component: () => import('@/views/station2/chargeDevData/index.vue')
            },
            {
                path: '/evmData',
                name: 'evmData',
                component: () => import('@/views/station2/evmData/index.vue')
            },
            {
                path: '/batDataInfo',
                name: 'batDataInfo',
                component: () => import('@/views/station2/batDataInfo/index.vue')
            },
// ========================================================================================================
            {
                path: '/ApkManage',
                name: 'ApkManage',
                component: () => import('@/views/StandAccused/ApkManage/index.vue')
            },
            //客户管理
            {
                path: '/customer',
                name: 'customer',
                component: () => import('@/views/customer/customer/index.vue')
            },
            {
                path: '/vehicleAuth',
                name: 'vehicleAuth',
                component: () => import('@/views/customer/vehicleAuth/index.vue')
            },
            {
                path: '/blacklist',
                name: 'blacklist',
                component: () => import('@/views/customer/blacklist/index.vue')
            },
            {
                path: '/member',
                name: 'member',
                component: () => import('@/views/operation2/member/index.vue')
            },
            {
                path: '/orderPayment',
                name: 'orderPayment',
                component: () => import('@/views/operation2/orderPayment/index.vue')
            },
            {
                path: '/order',
                name: 'order',
                component: () => import('@/views/customer/order/index.vue')
            },
            {
                path: '/swapOrder',
                name: 'swapOrder',
                component: () => import('@/views/customer/order/swapOrder.vue')
            },
            {
                path: '/chargeOrder',
                name: 'chargeOrder',
                component: () => import('@/views/customer/order/chargeOrder.vue')
            },

            // {
            //   path: '/client',
            //   name: 'client',
            //   component: () => import('@/views/operation/client/index.vue')
            // },
            {
                path: '/client',
                name: 'client',
                component: () => import('@/views/operation2/client/index.vue')
            },
            {
                path: '/client/:fcarNo',
                name: 'clientCarNo',
                component: () => import('@/views/operation2/client/info.vue')
            },
            {
                path: '/carInfo/:fcarNo',
                name: 'carInfo',
                component: () => import('@/views/operation2/client/carInfo.vue')
            },
            {
                path: '/audit/:fcarNo',
                name: 'audit',
                component: () => import('@/views/operation2/client/audit.vue')
            },
            {
                path: '/rechargeCard',
                name: 'rechargeCard',
                component: () => import('@/views/operation2/rechargeCard/index.vue')
            },
            {
                path: '/swapRefund',
                name: 'swapRefund',
                component: () => import('@/views/operation2/swapRefund/index.vue')
            },
            {
                path: '/swapRefund/:fid',
                name: 'swapRefundInfo',
                component: () => import('@/views/operation2/swapRefund/info.vue')
            },
            {
                path: '/swapRefundInfo/:fOrderNo',
                name: 'orderInfo',
                component: () => import('@/views/operation2/swapRefund/carInfo.vue')
            },
            {
                path: '/RefundInfo/:fid',
                name: 'RefundInfo',
                component: () => import('@/views/operation2/swapRefund/audit.vue')
            },
            // ========================================================================================================
            //电网电价模型
            {
                path: '/areaElec',
                name: 'areaElec',
                component: () => import('@/views/operation/areaElecModel/index.vue')
            },
            //电网电价分时模型--年月
            {
                path: '/elecDate',
                name: 'elecDate',
                component: () => import('@/views/operation/areaElecModel/elecDate.vue')
            },
            //电网电价分时模型--时分秒
            {
                path: '/elecTime',
                name: 'elecTime',
                component: () => import('@/views/operation/areaElecModel/elecTime.vue')
            },
            {
                path: '/stationRun',
                name: 'stationRun',
                component: () => import('@/views/operation/stationRunModel/index.vue')
            },
            {
                path: '/invoiceManage',
                name: 'invoiceManage',
                component: () => import('@/views/operation/invoiceManage/index.vue')
            },
            {
                path: '/stationBilling',
                name: 'stationBilling',
                component: () => import('@/views/operation/staBillingModel/index.vue')
            },

            {
                path: '/timeBilling',
                name: 'timeBilling',
                component: () => import('@/views/operation/staBillingModel/timeBilling.vue')
            },

            {
                path: '/setOpModel',
                name: 'setOpModel',
                component: () => import('@/views/operation/batteryModel/index.vue')
            },

            {
                path:'/faultReport',
                name:'faultReport',
                component: () => import('@/views/operation/faultReport/index.vue')
            },

            // ========================================================================================================
            //营收数据
            {
                path: '/station-revenue',
                name: 'station-revenue',
                component: () => import('@/views/revenue/station-revenue/index.vue')
            },
            {
                path: '/forcer-result',
                name: 'forcer-result',
                component: () => import('@/views/revenue/forcer-result/index.vue')
            },
            {
                path: '/forcer-area',
                name: 'forcer-area',
                component: () => import('@/views/revenue/forcer-area/index.vue')
            },
            {
                path: '/forcer-electro',
                name: 'forcer-electro',
                component: () => import('@/views/revenue/forcer-electro/index.vue')
            },
            {
                path: '/arerCode-revenue',
                name: 'arerCode-revenue',
                component: () => import('@/views/revenue/arercode-revenue/index.vue')
            },
            //故障处理
            {
                path: '/fault-msg',
                name: 'fault-msg',
                component: () => import('@/views/fault/fault-msg/index.vue')
            },
            {
                path: '/fault-time',
                name: 'fault-time',
                component: () => import('@/views/fault/fault-time/index.vue')
            },
            {
                path: '/fault-logging',
                name: 'fault-logging',
                component: () => import('@/views/fault/fault-logging/index.vue')
            },
            //电池投入
            {
                path: '/branch',
                name: 'branch',
                component: () => import('@/views/battery/branch/index.vue')
            },
            {
                path: '/cellPut',
                name: 'cellPut',
                component: () => import('@/views/battery/cellPut/index.vue')
            },
            {
                path: '/retrospect',
                name: 'retrospect',
                component: () => import('@/views/battery/retrospect/index.vue')
            },
            {
                path: '/safeguard',
                name: 'safeguard',
                component: () => import('@/views/battery/safeguard/index.vue')
            },
            //车辆管理
            {
                path: '/vehicleList',
                name: 'vehicleList',
                component: () => import('@/views/basics/vehicleManager/index'),
            },
            {
                path: '/registrationVehicles',
                name: 'registrationVehicles',
                component: () => import('@/views/basics/vehicleManager/registrationVehicles'),
            },
            {
                path: '/vehicleRemoteSetting',
                name: 'vehicleRemoteSetting',
                component: () => import('@/views/basics/vehicleManager/vehicleRemoteSetting'),
            },
            {
                path: '/electronicFenceSetup',
                name: 'electronicFenceSetup',
                component: () => import('@/views/basics/vehicleManager/electronicFenceSetup'),
            },

            //车辆实时数据信息
            {
                path: '/vehicleRealTimeData',
                name: 'vehicleRealTimeData',
                component: () => import('@/views/basics/vehicleManager/vehicleRealTimeData'),
            },

            //车辆黑白名单管理
            {
                path: '/vehicleBlackWhiteList',
                name: 'vehicleBlackWhiteList',
                component: () => import('@/views/basics/vehicleManager/vehicleBlackWhiteList'),
            },


            {
                path: '/vehicle-consume',
                name: 'vehicle-consume',
                component: () => import('@/views/vehicle/vehicle-consume/index.vue')
            },
            {
                path: '/vehicle-electric',
                name: 'vehicle-electric',
                component: () => import('@/views/vehicle/vehicle-electric/index.vue')
            },
            {
                path: '/vehicle-record',
                name: 'vehicle-record',
                component: () => import('@/components/vehicle-record/index.vue')
            },
            {
                path: '/UserCar',
                name: 'UserCar',
                component: () => import('@/views/vehicle/UserCar/index.vue')
            },
            //统计分析
            {
                path: '/electricStatistics',
                name: 'electricStatistics',
                component: () => import('@/views/statistics/electricStatistics/index.vue')
            },{
                path: '/revenueStatistics',
                name: 'revenueStatistics',
                component: () => import('@/views/statistics/revenueStatistics/index.vue')
            },
            {
                path: '/powerChanges',
                name: 'powerChanges',
                component: () => import('@/views/statistics/powerChanges/index.vue')
            },
            {
                path: '/force-basis',
                name: 'force-basis',
                component: () => import('@/views/statistics/force-basis/index.vue')
            },
            {
                path: '/force-link',
                name: 'force-link',
                component: () => import('@/views/statistics/force-link/index.vue')
            },
            {
                path: '/original-data',
                name: 'original-data',
                component: () => import('@/views/statistics/original-data/index.vue')
            },
            //  ====================================================================
            {
                path: '/elec-energy-basis',
                name: 'elec-energy-basis',
                component: () => import('@/views/statistics/elec-energy-basis/index.vue')
            },
            {
                path: '/elec-energy-link',
                name: 'elec-energy-link',
                component: () => import('@/views/statistics/elec-energy-link/index.vue')
            },
            {
                path: '/elec-statistics',
                name: 'elec-statistics',
                component: () => import('@/views/statistics/elec-statistics/index.vue')
            },
            {
                path: '/order-statistics',
                name: 'order-statistics',
                component: () => import('@/views/statistics/order-statistics/index.vue')
            },
            //  ====================================================================
        ]
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/404/index.vue')

    }

]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    const token = store.state.token
    if (!token && to.path !== '/login') {
        next({name: 'login'})
    } else if (!to.name) {
        next({name: '404'})
    }
    next()
})
export default router
