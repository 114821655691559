// const cloudBackUrl =  'http://127.0.0.1:8090';
// const cloudBackUrl = 'http://10.31.3.220:8090';
//const cloudBackUrl = 'https://solutionauto.harmontronics.com';
//const url = 'https://passengercar.harmontronics.com';
const url = 'https://cemicloud.cn';
//const url = 'http://127.0.0.1:8090';
//const url = 'http://10.10.81.112:8090';
//rest aes key
const restapiAesKey = "5F6B2AK33DASD1235E74C231B47AC8F1";

export const myconfig = {
    url:url,
    restApiAesKey: restapiAesKey
};
