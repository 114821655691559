import {createAPI} from '@/utils/request'
const urlStr = '/service-userinfo'

//分页查询首页轮播图
export const getAppBannerPage = data => createAPI(urlStr + `/appBanner/pageQuery` , 'get', data)

//新增首页轮播图
export const addAppBanner = (data) => createAPI(urlStr + '/appBanner/add' , 'post', data)

//更新首页轮播图
export const editAppBanner = (data) => createAPI(urlStr + '/appBanner/update' , 'post', data)

//禁用/启用首页轮播图
export const enableAppBanner = (id) => createAPI(urlStr + '/appBanner/enable/' + id, 'get')

//批量删除首页轮播图
export const batchDeleteAppBanner = (data) => createAPI(urlStr + '/appBanner/batchDelete?ids=' + data, 'get')


