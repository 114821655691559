import { createAPI } from '@/utils/request'
const urlStr = '/service-baseinfo'
    // POST
    // /emqtypebases/addEqmTypeBase
    // 设备型号基础信息_增加
export const addNewEqmTypeBase = data => createAPI(urlStr + '/emqtypebases/addEqmTypeBase', 'post', data)
    // DELETE
    // /emqtypebases/batch批量删除
export const delEqmTypeBase = id => createAPI(urlStr + '/emqtypebases/batch?idList=' + id, 'delete')
    // POST
    // /emqtypebases/excelImport/emqtypebases
    // 设备型号基础信息_批量导入
export const importExcelEqmTypeBases = data => createAPI(urlStr + '/emqtypebases/excelImport/emqtypebases', 'post', data)
    // GET
    // /emqtypebases/findall
    // 设备型号基础信息_查询所有
export const getEqmTypeAll = ()=>createAPI(urlStr+'/emqtypebases/findall','get')
    // GET
    // /emqtypebases/page
    // 分页查询设备型号列表
export const getAllEqmTypeBases = data => createAPI(urlStr + '/emqtypebases/page?page=' + data.page + '&size=' + data.size, 'get', data)
    // POST
    // /emqtypebases/update
    // 设备型号基础信息_修改
export const upEqmTypeBase = data => createAPI(urlStr + '/emqtypebases/update', 'post', data)

// /emqtypebases/specificPage
// 根据名称状态分页查询
export const getEqmTypeBaseOfStatus = data => createAPI(urlStr + '/emqtypebases/specificPage?name=' + data.name + "&state=" + data.state + "&page=" + data.page + "&size=" + data.size)
